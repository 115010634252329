import React, { useCallback, useEffect, useMemo } from "react"
import { useHits, useInstantSearch } from "react-instantsearch-core"
import { Colors } from "../../constants/Colors"
import { UiConstants } from "../../constants/UiConstants"
import { AlgoliaRecord, AlgoliaRecordType } from "../../data/models/AlgoliaRecord"
import { GroupBy } from "../../utils/GroupBy"
import { Flex } from "../Flex"
import { ShortArtistCell } from "../ShortArtistCell"
import { SkPill } from "../SkPill"
import { useHeaderSearchUiContext } from "./useSearchUiContext"
import { useNavigate } from "react-router-dom"
import { SkRoutes } from "../../constants/Routes"
import { SkCard } from "../Card"
import { SkText } from "../SkText"

import "./SearchHitContainer.scss";

export type HitType = AlgoliaRecordType | 'search'

export const SearchHitContainer = React.memo((props: {
    floating?: boolean
}) => {

    const { hits } = useHits<AlgoliaRecord>();
    const { status } = useInstantSearch();
    const { hideResults, resultsVisible, setNResults, query } = useHeaderSearchUiContext()
    const { floating } = props
    const nav = useNavigate()

    useEffect(() => { setNResults(hits?.length) }, [ hits?.length ])

    const { tags, artists } = useMemo(() => {
        const groups = GroupBy.All(hits, h => h.type)
        return { tags: groups['tag'] || [], artists: groups['artist'] || [] }
    }, [ hits ])

    const handleClickResult = useCallback((hit: Partial<AlgoliaRecord>) => {
        if (hit.type === 'artist' && hit.name) nav(SkRoutes.Artist.Show(hit.name))
        else if (hit.type === 'tag' && hit.name) nav(SkRoutes.Browse({ tags: [ hit.name ] }))
        else nav(SkRoutes.Browse({ q: hit.name }))
        hideResults()
    }, [ nav, hideResults ])

    if (!query || !resultsVisible) {
        return null;
    }

    const classes = [
        'component-searchHitContainer',
        ...(floating ? ['floating'] : ['fullscreen']),
    ];

    return (
        <div className={classes.join(' ')} style={{
            //...(styles.container),
            //...(floating ? styles.floatingContainer : styles.fullscreenContainer)
        }}>
            { tags?.length > 0 &&
                <>
                    <div className="search-hit-header">Tags</div>
                    <Flex row style={{ flexWrap: 'wrap', gap: '6px' }}>
                        { tags?.map((h, i) => <Hit key={h.objectID} h={h} index={i} onClick={ () => handleClickResult(h) } hitType='tag' />) }
                    </Flex>
                </>
            }
            { artists?.length > 0 && <div className="search-hit-header">Artists</div> }
            { artists?.map((h, i) => <Hit key={h.objectID} h={h} index={i + (tags?.length || 0)} onClick={ () => handleClickResult(h) } hitType='artist' />) }
            <Hit index={ -1 } onClick={ () => handleClickResult({ name: query }) } hitType='search' />
            {
                status === 'loading' 
                    && artists && artists.length === 0
                    && tags && tags.length === 0
                    && <div className="search-hit-header">Loading...</div>
            }
            {
                status === 'error' 
                    && <div className="search-hit-header">Error</div>
            }
            {
                status === 'idle' 
                    && artists && artists.length === 0
                    && tags && tags.length === 0
                    && <div className="search-hit-header">No Results</div>
            }
        </div>
    );

})

const Hit = React.memo((props: {
    h?: AlgoliaRecord,
    index: number,
    hitType: HitType
    onClick: () => void
}) => {

    const { h, index, onClick, hitType } = props

    const { selectedIndex, setSelectedDirective } = useHeaderSearchUiContext()
    const selected = index === selectedIndex
    useEffect(() => {
        if (selected) 
            setSelectedDirective({ type: hitType, object: h })
    }, [ selected, setSelectedDirective, h, hitType ])

    if(!h && hitType !== 'search') {
        return <SearchForQuery highlight={selected} onClick={onClick} />;
    }
    else if(!h) {
        return null;
    }
    else {
        switch (hitType) {
            case 'tag': return <SkPill label={h?.name} onClick={onClick} selected={selected} />
            case 'artist': return <ShortArtistCell artist={h} onClick={onClick} highlight={selected} />
            default: return null
        }
    }
})

const SearchForQuery = React.memo((props: {
    highlight: boolean
    onClick: () => void
}) => {

    const { highlight, onClick } = props
    const { selectedIndex, query } = useHeaderSearchUiContext()

    return (
        <SkCard style={{ backgroundColor: highlight ? Colors.DarkBackground : Colors.DarkerBackground }} onClick={ onClick }>
            <SkText variant='control-label'>Search for "{ query }"</SkText>
        </SkCard>
    )

})

const styles: Record<string, React.CSSProperties> = {

    container: {
        padding: UiConstants.tightSpacing,
        backgroundColor: Colors.InactiveGray,
    },

    fullscreenContainer: {
        width: '100%',
        boxSizing: 'border-box'
    },

    floatingContainer: {
        borderRadius: UiConstants.borderRadius,
        position: 'fixed',
        right: 100, top: 90,
        maxWidth: 400,
        minWidth: 400
    }

}
