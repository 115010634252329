import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { CgChevronRight } from 'react-icons/cg';
import { useNavigate } from "react-router-dom";
import { ArtistPlanet, ArtistPlanetList } from "../components/ArtistPlanet";
import { Bump } from "../components/Bump";
import { SkCard } from "../components/Card";
import { Feed, UserFeed } from "../components/Feed";
import { Flex } from "../components/Flex";
import { ScreenWrapper } from "../components/ScreenWrapper";
//import { SkImageTheater } from "../components/SkImageTheater";
import { SkText } from "../components/SkText";
import { WelcomeModal } from "../components/WelcomeModal";
import { CommissionColor, CommissionGradient } from "../constants/Colors";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { GetFollowedArtists } from "../data/actions/ArtistActions";
import { ArtistProfile } from "../data/models/Artist";
import { FeedItem } from "../data/models/FeedItem";
import { QueryKeys } from "../data/QueryKeys";
import { useAuth } from "../hooks/useAuth";
import { useCommissionStatusDescriptor } from "../hooks/useCommissionStatusDescriptor";
import { useSelectedFeedItem } from "../hooks/useSelectedFeedItem";
import { useToggle } from "../hooks/useToggle";
import { useWindowSize } from "../hooks/useWindowSize";
import { QueryString } from "../utils/QueryString";
import { ArtistSubscribeButton } from "./ArtistScreen/ArtistSubscribeButton";

import "./HomeScreen.scss";

export const HomeScreen = React.memo(() => {

    const { data } = useQuery(QueryKeys.User.Current.FollowedArtists(), GetFollowedArtists);
    const nav = useNavigate();
    const { isMobile } = useWindowSize();
    const intro = useToggle(undefined);
    const feedItem = useSelectedFeedItem();

    const selectedArtistHandle: string = QueryString.useParams().artist
    const selectedArtist = useMemo(() => data?.data?.find(a => a.handle === selectedArtistHandle), [ selectedArtistHandle, data ])
    const handleSelectArtist = (artist: ArtistProfile) => { nav(SkRoutes.Home(artist.handle)) }

    const { user } = useAuth();
    useEffect(() => { 
        if (intro.isUndefined && !!user) 
            intro.set(user.progress?.welcomed ? false : true) 
        //intro.set(true);
    }, [ user, intro ]);

    return (
        <ScreenWrapper
            className="page-home"
            left={(
                <ArtistPlanetList artists={ data?.data || [] } onSelect={ handleSelectArtist }
                    onAdd={() => nav(SkRoutes.Explore(''))} selectedHandle={ selectedArtist?.handle } />
            )}
        >
            {/* <Flex className="home-feed" align={ isMobile ? 'stretch' : 'center' }> */}
                { selectedArtist
                    ? <SelectedArtistFeed artist={ selectedArtist } onItemMediaClick={ feedItem.open } />
                    : <UserFeed onItemMediaClick={ feedItem.open } fillParent />
                }
            {/* </Flex> */}

            <WelcomeModal toggle={ intro } />

            {/* { feedItem.context?.item?.media && feedItem.context.item.media.length > 0 && 
                <SkImageTheater images={ feedItem.context?.item?.media } toggle={ feedItem.toggle } start={ feedItem.context?.selectedIndex } />
            } */}
        </ScreenWrapper>
    );

})

const SelectedArtistFeed = React.memo((props: { artist: ArtistProfile, onItemMediaClick: (item: FeedItem, index: number) => void }) => {

    const { artist, onItemMediaClick } = props
    const { isMobile } = useWindowSize()
    const nav = useNavigate()

    const status = useCommissionStatusDescriptor({ artist })

    return (
        <Flex className="selected-artist-feed">
            {/* <div style={{ width: '100%', padding: '0 10px', boxSizing: 'border-box' }}> */}
            
            <SkCard className="artist-profile-card" style={{
                background: CommissionGradient(artist.commissionStatus),
            }} onClick={ () => nav(SkRoutes.Artist.Show(artist.handle)) }>
                <Flex row align='center' style={{ padding: '10px 5px' }}>
                    { isMobile ? null : <ArtistPlanet artist={ artist } style={{ marginRight: 20 }} moonHaloColor='white' /> }
                    <Flex style={{ marginRight: 10 }}>
                        <SkText variant={ isMobile ? 'name' : 'c1' } style={{ fontWeight: 'bold' }}>{ artist.name }</SkText>
                        <SkText variant='c2'>{ status.shortLabel }</SkText>
                    </Flex>
                    <div style={{ flex: 1 }}></div>
                    <ArtistSubscribeButton artistHandle={ artist.handle } activeColor='white'
                        activeIconColor={ CommissionColor(artist.commissionStatus) }
                        inactiveColor='transparent' fadeIfInactive />
                    { isMobile ? (
                        null
                    ) : (
                        <>
                            <Bump w={ 10 } />
                            <CgChevronRight size={ 30 } color='white' />
                        </>
                    ) }
                </Flex>
            </SkCard>

            {/* </div> */}

            <Bump h={ isMobile ? 10 : 20 } />
            
            <Feed searchParams={{ artistHandles: [ artist.handle ] }} fillParent onItemMediaClick={ onItemMediaClick } noTrailingSpace />
        </Flex>
    )

})
