import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from "react";
import { create } from "zustand"
import { SkBackdrop } from "./SkModal"
import { SkCircleButton } from "./SkButton"
import { ToggleModel, useToggle } from "../hooks/useToggle";
import { BsX } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useGesture } from "@use-gesture/react";
import { animated, useSpring } from "@react-spring/web";
import { useWindowSize } from "../hooks/useWindowSize";

import "./SkImageTheater.scss";

type GestureStates = 'none' | 'dragging-horizontal' | 'dragging-vertical' | 'zooming';
const DEAD_ZONE = 10;
const SWIPE_VELOCITY_THRESHOLD_X = 0.2;
const SWIPE_VELOCITY_THRESHOLD_Y = 0.5;

// export type SkImageTheaterContext = {

//     isOpen: boolean
//     images: string[]
//     visibleIndex: number

//     open: (images: string[], idx?: number) => void
//     close: () => void
//     next: () => void
//     prev: () => void
//     getNImages: () => number
//     getCurrentImageUrl: () => string
// }

// export const useSkImageTheater = create<SkImageTheaterContext>((set, get) => {

//     return {

//         isOpen: false,
//         images: [],
//         visibleIndex: 0,

//         open: (images, idx) => {
//             if (idx !== undefined) set({ visibleIndex: idx })
//             set({ images, isOpen: true })
//         },

//         close: () => {
//             set({ images: [], isOpen: false, visibleIndex: 0 })
//         },

//         next: () => {
//             const nextIdx = get().visibleIndex + 1
//             const maxIdx = get().getNImages() - 1
//             if (nextIdx > maxIdx) return;
//             set({ visibleIndex: nextIdx })
//         },

//         prev: () => {
//             let nextIdx = get().visibleIndex - 1
//             if (nextIdx < 0) return;
//             set({ visibleIndex: nextIdx })
//         },

//         getNImages: () => get().images?.length || 1,

//         getCurrentImageUrl: () => get().images?.[get().visibleIndex] || ''

//     }
// });

export interface SkImageTheaterContextProps {
    showImages: (imageUrls: string[], startIndex?: number) => void;
    close: () => void;
}

export const SkImageTheaterContext = React.createContext<SkImageTheaterContextProps>({
    showImages: (imageUrls: string[], startIndex?: number) => {},
    close: () => {},
});

export const SkImageTheaterProvider = (props: ComponentProps<any>) => {
    const { children } = props;
    const theater = useToggle(false);
    const [index, setIndex] = useState<number>(0);
    const [imageUrls, setImageUrls] = useState<string[]>([]);

    const showImages = useCallback((imageUrls: string[], startIndex: number = 0) => {
        setImageUrls(imageUrls);
        setIndex(startIndex);
        theater.open();
    }, [theater]);

    const close = useCallback(() => {
        theater.close();
        setImageUrls([]);
        setIndex(0);
    }, [theater]);

    return (
        <SkImageTheaterContext.Provider value={{ showImages, close }}>
            {children}
            <SkImageTheater isOpen={theater.isOpen} images={imageUrls} index={index} onRequestClose={theater.close} onIndexChange={setIndex} />
        </SkImageTheaterContext.Provider>
    );
}

export interface SkImageTheaterProps {
    isOpen: boolean;
    images: string[];
    index: number;
    onRequestClose: () => void;
    onIndexChange: (index: number) => void;
}

export const SkImageTheater = React.memo((props: SkImageTheaterProps) => {

    const { isMobile } = useWindowSize();
    const { isOpen, images, index, onRequestClose, onIndexChange } = props;

    const go = useCallback((index: number) => {
        if(index >= 0 && index < images.length) {
            onIndexChange(index);
        }
    }, [images, onIndexChange]);

    return (
        <SkBackdrop open={isOpen} onClick={!isMobile ? onRequestClose : undefined}>
            <>
                {!isMobile && <ImageTheaterDesktop isOpen={isOpen} images={images} index={index} onRequestClose={onRequestClose} goToIndex={go} />}
                {isMobile && <ImageTheaterMobile isOpen={isOpen} images={images} index={index} onRequestClose={onRequestClose} goToIndex={go} />}
            </>
            {/* <div className="component-skImageTheater">
                <SkCircleButton htmlId='image-theater-close' variant='secondary' onClick={ theater.close }>
                    <BsX size={ 30 } />
                </SkCircleButton>

                <div className="theater-container">
                    <FullHeightMove direction='backward' onClick={theater.prev} disabled={theater.visibleIndex <= 0} />
                    <div className="theater-image" style={{ backgroundImage: `url(${theater.getCurrentImageUrl()})` }}></div>
                    <FullHeightMove direction='forward' onClick={theater.next} disabled={theater.visibleIndex >= theater.getNImages() - 1 } />
                </div>
            </div> */}
        </SkBackdrop>
    );
});

interface ImageTheaterProps {
    isOpen: boolean;
    images: string[];
    index: number;
    onRequestClose: () => void;
    goToIndex: (change: number) => void;
}

const ImageTheaterDesktop = React.memo((props: ImageTheaterProps) => {

    const { images, index, onRequestClose, goToIndex } = props;

    return (
        <div className="component-skImageTheater mobile-theater">
            <SkCircleButton htmlId='image-theater-close' variant='secondary' onClick={onRequestClose}>
                <BsX size={ 30 } />
            </SkCircleButton>

            <div className="theater-container">
                <FullHeightMove direction='backward' onClick={ () => goToIndex(index - 1) } disabled={ index <= 0 } />

                <div key={images[index]} className="theater-image">
                    <div className="image" style={{ backgroundImage: `url(${images[index]})` }}></div>
                    <img src={images[index]} style={{ width: '100%' }} alt="" />
                </div>

                <FullHeightMove direction='forward' onClick={ () => goToIndex(index + 1) } disabled={ index >= images.length - 1 } />
            </div>
        </div>
    );
});

const ImageTheaterMobile = (props: ImageTheaterProps) => {

    const { isOpen, images, index, goToIndex, onRequestClose } = props;
    const [gestureState, setGestureState] = useState<GestureStates>('none');

    const horizontalSpringconfig = useMemo(() => ({
        mass: 1,
        tension: 280,
        friction: 20,
        bounce: 0
    }), []);

    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        config: { mass: 1, tension: 350, friction: 20, bounce: 0.2 }
    }));

    useEffect(() => {
        if(isOpen) {
            api.set({ x: -(index * window.innerWidth), y: 0 });
        }
    }, [api, isOpen]);

    useEffect(() => {
        if(index >= 0 && index < images.length) {
            api.start({ x: -(index * window.innerWidth), config: horizontalSpringconfig });
            console.log('index changed to ', index);
        }
    }, [api, index, images, horizontalSpringconfig]);

    const bind = useGesture({
        onDrag: (state) => {
            // Cancel drag if we're in another gesture
            if(['dragging-horizontal', 'dragging-vertical', 'none'].includes(gestureState) === false) {
                state.cancel();
            }

            if(gestureState === 'none') {
                if(state.direction[0] !== 0 && state.distance[0] > DEAD_ZONE) {
                    setGestureState('dragging-horizontal');
                }
                else if(state.direction[1] !== 0 && state.distance[1] > DEAD_ZONE) {
                    setGestureState('dragging-vertical');
                }
            }
            else if(state.last) {
                if(gestureState === 'dragging-vertical') {
                    if(state.velocity[1] > SWIPE_VELOCITY_THRESHOLD_Y) {
                        onRequestClose();
                        if(state.movement[1] > 0) {
                            api.start({ y: window.innerHeight, config: { tension: 60, friction: 0, bounce: 0 }});
                        }
                        else {
                            api.start({ y: -window.innerHeight, config: { tension: 60, friction: 0, bounce: 0 } });
                        }
                    }
                    else {
                        api.start({ y: 0 });
                    }
                }
                else if(gestureState === 'dragging-horizontal') {

                    const currentCenter = Math.abs(Math.floor((state.movement[0] - (index * window.innerWidth) - (window.innerWidth / 2)) / window.innerWidth)) - 1;

                    // If velocity is high enough and we're swiping left (to next picture)
                    if((
                        (state.velocity[0] > SWIPE_VELOCITY_THRESHOLD_X && state.movement[0] < 0) && index < images.length - 1)) {
                        console.log('swipe left to next picture');
                        goToIndex(index + 1);
                        console.log('velocity swiped index to ', index + 1);
                    }
                    // If velocity is high enough and we're swiping right (to previous picture) 
                    else if(state.velocity[0] > SWIPE_VELOCITY_THRESHOLD_X && state.movement[0] > 0 && index > 0) {
                        console.log('swipe right to previous picture');
                        goToIndex(index - 1);
                        console.log('velocity swiped index to ', index - 1);
                    }
                    else if(currentCenter !== index && currentCenter >= 0 && currentCenter < images.length) {
                        console.log('swipe to center: ', currentCenter);
                        goToIndex(currentCenter);
                        console.log('non-velocity swiped index to ', currentCenter);
                    }
                    else {
                        api.start({ x: -(index * window.innerWidth), config: horizontalSpringconfig });
                    }
                }
    
                setGestureState('none');
            }
            else {
                if(gestureState === 'dragging-horizontal') {
                    api.start({ x: state.movement[0] - (index * window.innerWidth), config: { tension: 350, friction: 0, bounce: 0 } });
                    //api.set({ x: state.movement[0] - (index * window.innerWidth) });
                }
                else if(gestureState === 'dragging-vertical') {
                    api.start({ y: state.movement[1], config: { tension: 350, friction: 0, bounce: 0 } });
                    //api.set({ y: state.movement[1] });
                }
            }
        },
        onPinch: (state) => {
            // Cancel pinch if we're already in another gesture
            if(['zooming', 'none'].includes(gestureState) === false) {
                state.cancel();
            }
        }
    });

    return (
        <div className="component-skImageTheater mobile-theater">
            <SkCircleButton htmlId='image-theater-close' variant='secondary' onClick={onRequestClose}>
                <BsX size={ 30 } />
            </SkCircleButton>

            <div className="theater-container">
                { images?.map((imgUrl, index) => {
                    return (
                        <animated.div {...bind(index)} key={imgUrl} className="theater-image" style={{...style}}>
                            <div className="image" style={{ backgroundImage: `url(${imgUrl})` }}></div>
                            <img src={imgUrl} style={{ width: '100%' }} alt="" />
                        </animated.div>
                    )
                }) }
            </div>
        </div>
    );
};

const FullHeightMove = React.memo((props: { direction: 'forward' | 'backward', disabled: boolean, onClick: () => void }) => 
{
    const { direction, disabled, onClick } = props

    const { icon } = useMemo(() => {
        switch(direction) {
            case 'backward': return { icon: <FaChevronLeft color='white' size={ 30 } /> }
            case 'forward': return { icon: <FaChevronRight color='white' size={ 30 } /> }
        }
    }, [ direction ])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (!disabled) {
            onClick?.()
        }
    };

    return (
        <div id={`image-theater-${direction}`} className={disabled ? 'disabled' : ''} onClick={handleClick}>
            {icon}
        </div>
    );
});