import React, { useEffect, useMemo, useState } from "react"
import { UiConstants } from "../constants/UiConstants"
import { Flex } from "./Flex"

import "./ImageGrid.scss";
import { SkButton } from "./SkButton";

const spacing = 0;

export interface ImageGridProps {
    imageUrls: string[];
    height: number;
    onImageClick?: (index: number) => void;
    mode?: 'normal' | 'blurred';
}

export const ImageGrid = (props: ImageGridProps) => {

    const { height, onImageClick, mode = 'normal' } = props;
    const [showBlur, setShowBlur] = useState(mode === 'blurred');
    const imageUrlsRaw = props.imageUrls;
    const imageUrls = imageUrlsRaw?.filter(u => !!u) || [];
    const c = imageUrls?.length;
    const handler = (index: number) => (() => onImageClick?.(index));
    let content: React.ReactNode = null;

    if (!c) {
        return null;
    } 
    else if(c === 1) {
        content = (
            <>
                <GridImage url={ imageUrls[0] } onClick={ handler(0) } />
            </>
        )
    }
    else if(c === 2) {
        content = (
            <>
                <GridImage url={ imageUrls[0] } height={ height } bumper='right' onClick={ handler(0) } />
                <GridImage url={ imageUrls[1] } height={ height } bumper='left' onClick={ handler(1) } />
            </>
        )
    }
    else if(c === 3) {
        content = (
            <>
                <div className="left-grid">
                    <GridImage url={ imageUrls[0] } height={ height } bumper='right' onClick={ handler(0) } />  
                </div>
                <div className="right-grid">
                    <GridImage url={ imageUrls[1] } height={ height / 2 } bumper='bottom' onClick={ handler(1) } />
                    <GridImage url={ imageUrls[2] } height={ height / 2 } bumper='top' onClick={ handler(2) }/>
                </div>
            </>
        )
    }
    else if(c === 4) {
        content = (
            <>
                <GridImage url={ imageUrls[0] } height={ height / 2 } bumper='right' onClick={ handler(0) }/>
                <GridImage url={ imageUrls[1] } height={ height / 2 } bumper='left' onClick={ handler(1) }/>
                <GridImage url={ imageUrls[2] } height={ height / 2 } bumper='right' onClick={ handler(2) }/>
                <GridImage url={ imageUrls[3] } height={ height / 2 } bumper='left' onClick={ handler(3) }/>
            </>
        );
    }

    return (
        <div className={`image-grid ${mode} image-grid-${c}`}>
            { showBlur === true && mode === 'blurred' && 
                <div className="grid-blur" onClick={() => setShowBlur(false)}>
                    <p>This post contains NSFW content</p>
                    <p className="blur-button-mobile">Tap to show</p>
                    <p className="blur-button-desktop">Click to show</p>
                </div>
            }
            { showBlur === false && mode === 'blurred' &&
                <div className="grid-hide">
                    <SkButton variant="outlined" size="small" onClick={() => setShowBlur(true)}>Hide</SkButton>
                </div>
            }
            {content}
        </div>
    );
};

const GridImage = React.memo((props: { url: string, height?: number, bumper?: 'left' | 'right' | 'bottom' | 'top', onClick?: () => void, style?: React.CSSProperties }) => {

    const { url, height, bumper, onClick, style } = props

    // const bumpStyles = useMemo(() => {
    //     switch (bumper)  {
    //         case 'left': return { marginLeft: spacing }
    //         case 'right': return { marginRight: spacing }
    //         case 'bottom': return { marginBottom: spacing }
    //         case 'top': return { marginTop: spacing }
    //         default: return {}
    //     }
    // }, [ bumper ]);

    return (
        // <div className="grid-image" style={{ backgroundImage: `url(${ url })`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height, cursor: 'pointer', ...bumpStyles, ...style }} onClick={ onClick }>
        <div className="grid-image" onClick={onClick}>
            <img src={url} alt="" />
        </div>
    );
});
