import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../constants/Colors";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { GetFollowedArtists } from "../data/actions/ArtistActions";
import { GetEmptyList } from "../data/actions/ControlActions";
import { GetFeedAction, OpenArtistsFeedAction, OpeningSoonArtistsFeedAction, SearchPostsAction } from "../data/actions/FeedActions";
import { FeedItem } from "../data/models/FeedItem";
import { QueryKeys } from "../data/QueryKeys";
import { PostSearchParams } from "../data/requests/PostSearchParams";
import { useCommissionStatusDescriptor } from "../hooks/useCommissionStatusDescriptor";
import { useWindowSize } from "../hooks/useWindowSize";
import { FormatDate } from "../utils/FormatDate";
import { FormatString } from "../utils/FormatString";
import { ArtistPlanet } from "./ArtistPlanet";
import { Bump } from "./Bump";
import { Flex } from "./Flex";
import { ImageGrid } from "./ImageGrid";
import { SkText } from "./SkText";
import { LoadingBox } from "./LoadingBox";

import "./Feed.scss";
import { UserContext } from "../data/contexts/UserContext";
import { CurrentUser } from "../data/models/CurrentUser";
import { SkImageTheaterContext } from "./SkImageTheater";

interface FeedProps {
    searchParams?: PostSearchParams,
    preset?: 'open' | 'opening-soon', // | 'user'
    noTrailingSpace?: boolean
    fillParent?: boolean
    onItemMediaClick?: (item: FeedItem, start: number) => void
}

/**@deprecated Please use \<Feed preset='user' /\> */
export const UserFeed = React.memo((props: { onItemMediaClick?: (item: FeedItem, start: number) => void, fillParent?: boolean }) => {

    const following = useQuery(QueryKeys.User.Current.FollowedArtists(), () => GetFollowedArtists())
    const artistHandles: string[] = useMemo(() => {
        return following?.data?.data?.map(a => a.handle) || []
    }, [ following ])

    return <Feed searchParams={{ artistHandles }} onItemMediaClick={ props.onItemMediaClick } noTrailingSpace fillParent={ props.fillParent } />
})

export const Feed = React.memo((props: FeedProps) => {

    const { searchParams, preset, onItemMediaClick, noTrailingSpace, fillParent } = props

    const { queryKeys, queryAction } = useMemo(() => {

        switch (preset) {
            //case 'user': return { queryKeys: QueryKeys.User.Current.Feed(), queryAction: () => GetFeedAction() }
            case 'open': return { queryKeys: QueryKeys.Posts.Open(searchParams?.tags), queryAction: () => OpenArtistsFeedAction(searchParams?.tags) }
            case 'opening-soon': return { queryKeys: QueryKeys.Posts.OpeningSoon(searchParams?.tags), queryAction: () => OpeningSoonArtistsFeedAction(searchParams?.tags) }
            default: break;
        }

        if (!searchParams) return { queryKeys: QueryKeys.Nothing(), queryAction: () => GetEmptyList<FeedItem>() }
        return { queryKeys: QueryKeys.Posts.Search(searchParams), queryAction: () => SearchPostsAction(searchParams) }

    }, [ searchParams, preset ])

    const { data, error, isLoading } = useQuery(queryKeys, queryAction)
    const { isMobile } = useWindowSize()

    const items = data?.data || []
    const noData = !isLoading && !data?.data?.length

    return (
        <Flex className="component-feed" 
            // align='stretch'  style={{ minWidth: (isMobile || fillParent) ? undefined : UiConstants.feedItemWidth, margin: '0 10px' }}
        >
            {
                items.map(i => (
                    <FeedItemDisplay key={i.id} item={ i } onImageClick={ (index) => onItemMediaClick?.(i, index) } />
                ))
            }
            { noData ? (
                <Flex justify='center' align='center' style={{ width: '100%', margin: '40px 5px', boxSizing: 'border-box', opacity: 0.4 }}>
                    <SkText variant='c1' faded>No Posts Found</SkText>
                </Flex>
            ) : null }
            { noTrailingSpace ? null : <Bump h={ 100 } /> }
        </Flex>
    );
})

export interface FeedItemDisplayProps {
    item: FeedItem;
    onImageClick: (index: number) => void;
}

export const FeedItemDisplay = React.memo((props: FeedItemDisplayProps) => {

    const { user } = useContext(UserContext);
    const { item, onImageClick } = props;
    const theaterContext = useContext(SkImageTheaterContext);
    const { isMobile } = useWindowSize();
    const nav = useNavigate();

    const status = useCommissionStatusDescriptor({ feedItem: item })
    const lines = useMemo(() => (item.message?.split('\n') || []).map(l => FormatString.DecodeHtmlEntitiesQuickNDirty(l || '&nbsp;')), [ item.message ])

    const handleImageClick = useCallback((index: number) => {
        theaterContext.showImages(item.media || [], index);
    }, [theaterContext, item.media]);

    return (
        <div className="feed-item">
            {
                status.noStatus ? null : (
                    <div className="status-bar" style={{ background: status.gradient }}> 
                        <SkText variant='c2' style={{ fontWeight: 'bold' }}>{ status.longLabel }</SkText>
                    </div>
                )
            }

            <div className="feed-item-content">
                <Flex row style={{ marginBottom: isMobile ? 0 : 10 }} onClick={ () => nav(SkRoutes.Artist.Show(item.artistHandle)) }>
                    <ArtistPlanet artist={ item.artist } moonHaloColor={ Colors.DarkerBackground } />

                    <div style={{ marginLeft: 15, flex: 1 }}>
                        <Flex style={{ width: '100%', marginBottom: 15 }}>
                            <SkText variant='name'>{ item.artist?.name || '(Missing artist)' }</SkText>
                            <SkText variant='descriptor' faded style={{ marginTop: 5 }}>{ FormatDate.adaptiveTime(item.timestamp) }</SkText>
                        </Flex>
                    </div>

                </Flex>

                { lines.map((line, index) => (
                    // <SkText variant='c2' style={{ display: 'block' }} onClick={ () => nav(SkRoutes.Feed.Post(item.artist?.handle, item.id)) }>{ l }</SkText>
                    // <SkText variant="c2" style={{ display: 'block' }}>{l}</SkText>
                    <div key={`${line}-${index}`}>{line}</div>
                )) }


                {/* <Flex row style={{ marginTop: 15, flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}> */}
                <div className="feed-item-tags">
                {
                    item.tags?.map(t => (
                        // <SkText variant='descriptor' faded style={{ marginRight: 20, marginBottom: 5 }} onClick={ () => nav(SkRoutes.Browse({ tags: [ t ] })) }>{ t }</SkText>
                        <TagButton key={t} tag={t} />
                    ))
                }
                {
                    item.postUrl ? (
                        <SkText href={ item.postUrl } variant='descriptor' style={{ marginLeft: '10px' }}>
                            View Post
                        </SkText>
                    ) : null
                }
                </div>
                {/* </Flex> */}

            </div>

            { item.thumbnails && item.thumbnails?.length > 0 &&
                <div className="feed-item-media">
                    <ImageGrid mode={shouldBlur(item, user) ? 'blurred' : 'normal'} imageUrls={item.thumbnails} height={300} onImageClick={handleImageClick} />
                </div>
            }
        </div>
    )
})

export const FeedSkeleton = React.memo(() => {

    return (
        <Flex column>
            <LoadingBox height={ 500 } />
            <Bump h={ 20 } />
            <LoadingBox height={ 175 } />
            <Bump h={ 20 } />
            <LoadingBox height={ 350 } />
        </Flex>
    )

})

export const TagButton = React.memo((props: { tag: string }) => {
    
    const { tag } = props;    
    const nav = useNavigate();

    const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        nav(SkRoutes.Browse({ tags: [ tag ] }));
        e.preventDefault();
    }, [ tag ]);
    
    return (
        <a className="tag-button" onClick={handleClick} href={SkRoutes.Browse({ tags: [tag] })}>{tag}</a>
        // <SkText variant='descriptor' faded style={{ marginRight: 20, marginBottom: 5 }} onClick={ () => nav(SkRoutes.Browse({ tags: [ props.tag ] })) }>{ props.tag }</SkText>
    );
})

const shouldBlur = (item: FeedItem, user?: CurrentUser) => {
    const includesNsfwLabel = (['porn', 'sexual', 'sexual-figurative', 'nudity', 'graphic-media'].some(t => item.labels?.includes(t)));

    if(user?.preferences?.nsfw === 'show') {
        return false;
    }
    
    return includesNsfwLabel;
}