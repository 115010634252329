import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { BsCamera, BsPen, BsPencil } from "react-icons/bs";
import { MdBadge, MdOutlineBadge } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Bump } from "../components/Bump";
import { Flex } from "../components/Flex";
import { NotificationMethodCard } from "../components/Notifications/NotificationMethodCard";
import { NotificationSetupForm } from "../components/Notifications/NotificationSetupForm";
import { ScreenWrapper } from "../components/ScreenWrapper";
import { SkButton, SkCircleButton } from "../components/SkButton";
import { SkModal, SkModalActions } from "../components/SkModal";
import { SkPill } from "../components/SkPill";
import { SkText } from "../components/SkText";
import { SkTextInput } from "../components/SkTextInput";
import { UserImage } from "../components/UserImage";
import { Colors } from "../constants/Colors";
import { NotificationMethod } from "../constants/NotificationMethod";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { SeedTagsAction } from "../data/actions/TagActions";
import { MakeUrl } from "../data/actions/Urls";
import { GetCurrentUserAction, UnsetUserProgress, UpdateCurrentUserAction } from "../data/actions/UserActions";
import { QueryKeys } from "../data/QueryKeys";
import { useAuth } from "../hooks/useAuth";
import { useToggle } from "../hooks/useToggle";
import { useWindowSize } from "../hooks/useWindowSize";
import { Notifications } from "../utils/Notifications";
import { ReactQuery } from "../utils/ReactQuery";
import { FiAtSign } from 'react-icons/fi';
import * as Yup from "yup";

import "./ProfileScreen.scss";
import { Formik, Form } from "formik";
import { SkTextField } from "../components/Forms/SkTextField";
import { useUser } from "@clerk/clerk-react";

export const ProfileScreen = React.memo(() => {

    const auth = useAuth();
    const { user } = useUser();
    const updateProfileModal = useToggle(false);
    const notifUpdateModal = useToggle(false);

    const [notifMethodToUpdate, setNotifMethodToUpdate] = useState<NotificationMethod>('telegram')
    const meta = Notifications.useNotificationMethodStatus(notifMethodToUpdate)
    const handleOpenNotifModal = (method: NotificationMethod) => {
        setNotifMethodToUpdate(method)
        notifUpdateModal.open()
    };

    return (
        <ScreenWrapper className="profile-screen" padded>

            <h1>Settings</h1>
            <hr />

            <div className="profile-info">
                <div className="profile-name">
                    <UserImage size={64} />
                    <div>
                        <h2>{auth.user?.name}</h2>
                        <div className="subtle">{auth.user?.username} {auth.user?.role === 'admin' ? '(ADMIN)' : ''}</div>
                    </div>
                </div>
                <div className="profile-buttons">
                    {/* <SkButton variant="outlined" title="Edit Profile" onClick={updateProfileModal.open} /> */}
                    <SkButton variant="outlined" title="Edit Profile" onClick={() => auth.openUserProfile() } />
                </div>
            </div>
            

            <h3>Notifications</h3>
            <hr />
            <p>Skrunkle can let you know you when your favorite artist opens for commissions! Set up at least one method below to start receiving notifications from artists you're subscribed to.</p>
            <p>While we try our <a href="https://bean.best" target="_blank" rel="noreferrer">bean best</a> to send notifications as soon as an artist announces an opening, due to technical limitations, <b>notifications may arrive up to an hour after posting</b>. (We're working to speed that up!)</p>

            <h4>Telegram</h4>
            <div className="subtle">Set up Telegram notifications to receive messages from <a href="https://t.me/SkrunkleBot" target="_blank" rel="noreferrer">@SkrunkleBot</a> when your favorite artists open.</div>
            <NotificationMethodCard method='telegram' onClick={() => handleOpenNotifModal('telegram')} />

            <h4>Browser Push Notifications</h4>
            <div className="subtle">Set up browser notifications to receive push notifications from your browser. Note that notifications will only be received on the single device you configure. If you configure browser notifications on a different device, your original device will be automatically unsubscribed.</div>
            <NotificationMethodCard method='webpush' onClick={() => handleOpenNotifModal('webpush')} />

            <h3>Content Visiblity</h3>
            <hr />

            <h4>Explicit Content (NSFW, Suggestive, Graphic)</h4>
            <p>Choose how you'd like to view explicit content on Skrunkle. If you choose to hide all explicit content, you will not see any posts marked as NSFW, suggestive, or graphic.</p>
            
            <div style={{ margin: '10px 0' }}>
                <select>
                    <option>Show all posts</option>
                    <option>Warn me</option>
                    <option>Hide all</option>
                </select>
            </div>
            <div className="subtle">We rely on artists to mark their posts appropriately. Some NSFW posts may slip through the cracks if not tagged properly.</div>
            

            <AdminFeatures />

            <h3>Account</h3>
            <hr />
            <SkButton variant='primary' style={{ backgroundColor: Colors.FailureRed }} title='Log Out' onClick={auth.signOut} />

            <h3>Support</h3>
            <hr />

            <h4>Help, Feedback, Bug Reports and Responsible Disclosure</h4>
            <p>For help with Skrunkle or to submit feedback, a bug report, or responsible disclosure of a security issue, please send us a direct message on Bluesky (<a href="https://bsky.app/profile/skrunkle.com" target="_blank" rel="noreferrer">@skrunkle.com</a>) or Twitter (<a href="https://twitter.com/SkrunkleArt">@SkrunkleArt</a>).</p>

            <h4>Artist Opt-Out</h4>
            <p>Artists are added to Skrunkle based on community suggestions. If you are an artist and would like to opt-out of being listed on Skrunkle, please send us a direct message on Bluesky (<a href="https://bsky.app/profile/skrunkle.com" target="_blank" rel="noreferrer">@skrunkle.com</a>) or Twitter (<a href="https://twitter.com/SkrunkleArt">@SkrunkleArt</a>). Be sure to send us a direct message from the account that is listed on Skrunkle to help us verify the request is legitimate.</p>

            <h4>Privacy Policy & Terms of Service</h4>
            <p>By using Skrunkle, you agree to our <a href="/privacy/index.html" target="_blank">Privacy Policy</a> and <a href="/terms/index.html" target="_blank">Terms of Service</a>.</p>

            <hr />

            <SkModal open={notifUpdateModal.isOpen} onClose={notifUpdateModal.close} title={`${meta?.name} Setup`}>
                <NotificationSetupForm method={notifMethodToUpdate} onDone={notifUpdateModal.close} />
            </SkModal>

            <SkModal open={updateProfileModal.isOpen} onClose={updateProfileModal.close} title='Update Profile'>
                {/* <PfpUpdateForm onDone={updateProfileModal.close} /> */}
                <ProfileUpdateForm onRequestClose={updateProfileModal.close} />
            </SkModal>

            {/* <Flex row={!isMobile} align='stretch'>

                <Flex style={{ margin: '60px 0', width: isMobile ? '100%' : 350 }}>
                    <UserImage size={200} onClick={updateProfileModal.open} />
                    <SkText variant='section' style={{ marginTop: 20 }}>{auth.user?.name}</SkText>
                    <SkText variant='c1' faded>{auth.user?.username}</SkText>
                    <Bump h={UiConstants.tightSpacing} />
                    {
                        (auth.user?.role && auth.user?.role !== 'user') ? (
                            <SkPill selected label={auth.user?.role} style={{ textTransform: 'uppercase' }} textVariant='descriptor'
                                before={<MdOutlineBadge color='white' style={{ marginRight: UiConstants.tightSpacing }} />} />
                        ) : null
                    }
                    <Bump h={UiConstants.standardSpacing} />
                    <SkButton variant='secondary-invert' icon={<BsPencil color='white' size={20} />} title='Edit Profile' onClick={updateProfileModal.open} />
                </Flex>

                <Bump w={UiConstants.standardSpacing} />

                <Flex style={{ flex: 1 }}>
                    <SkText variant='section'>Notifications</SkText>
                    <Flex row style={{ marginBottom: 40, marginTop: 20, maxWidth: '100%', overflow: 'scroll' }}>
                        <NotificationMethodCard method='telegram' onClick={() => handleOpenNotifModal('telegram')} />
                        <NotificationMethodCard method='webpush' onClick={() => handleOpenNotifModal('webpush')} />
                    </Flex>

                    <AdminFeatures />
                    <SkButton variant='primary' style={{ backgroundColor: Colors.FailureRed, marginTop: 80, marginBottom: 80 }} title='Log Out' onClick={auth.signOut} />
                </Flex>

            </Flex> */}

        </ScreenWrapper>
    );

});

const AdminFeatures = React.memo(() => {

    const auth = useAuth()
    const nav = useNavigate();

    if (auth.user?.role !== 'admin') return null;

    const handleSeedTags = async () => {
        const result = await SeedTagsAction()
        const { created, deleted, total } = result.data || {}
        alert(`Created ${created} tag(s), deleted ${deleted} tag(s). Database now contains ${total} tags.`)
    }

    return (
        <>
            <h3>Admin</h3>
            <hr />
            <div className="admin-controls">
                <SkButton title='View App Stats' onClick={() => nav(SkRoutes.Admin.AppStats())} />
                <SkButton title='View Reports' onClick={() => nav(SkRoutes.Admin.Reports())} />
                <SkButton title='Add An Artist' variant='outlined' onClick={() => nav(SkRoutes.Artist.Create())} />
                <SkButton title='Seed Tags' variant='outlined' onClick={handleSeedTags} />
                <SkButton title='Reset Welcome Modal' variant='outlined' onClick={() => UnsetUserProgress('welcomed')} />
                <SkButton title='Send Test Web Push' variant='outlined' onClick={() => axios.post(MakeUrl('/notifs/wpush/test'))} />
            </div>
        </>
    )

})

const ProfileUpdateForm = React.memo((props: { onRequestClose: () => void }) => {

    const { onRequestClose } = props;
    const { user } = useAuth();

    const schema = Yup.object({
        displayName: Yup.string().required('Please enter your name').trim(),
        email: Yup.string()
    });

    let initialData = {
        displayName: user?.name || '',
        email: user?.email || ''
    }

    return (
        <Formik initialValues={initialData} validationSchema={schema} onSubmit={async (values, { setSubmitting }) => {
            console.log('submitting', values);
            setSubmitting(false);
        }
        }>
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <SkTextField name="displayName" type="text" label="Display Name" layout="vertical" />
                    <SkTextField name="email" type="email" label="Email" disabled layout="vertical" />

                    <SkModalActions>
                        <SkButton variant="secondary" title="Cancel" onClick={onRequestClose} />
                        <SkButton variant="primary" title="Save" type="submit" disabled={!isValid || !dirty || isSubmitting} />
                    </SkModalActions>
                </Form>
            )}
        </Formik>
    )

});

const PfpUpdateForm = React.memo((props: { onDone?: () => void }) => {

    const { onDone } = props
    const user = useQuery(QueryKeys.User.Current.Profile(), GetCurrentUserAction)
    const [loaded, setLoaded] = useState(false);
    const [pfpImage, setPfpImage] = useState<string>('');
    const [username, setUsername] = useState<string>('')
    const [handle, setHandle] = useState<string>('')
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if (loaded) return;
        if (!user?.data?.data?.name) return;
        if (!pfpImage) setPfpImage(user?.data?.data?.imageUrl)
        if (!username) setUsername(user?.data?.data?.name)
        if (!handle) setHandle(user?.data?.data?.username)
        setLoaded(true)
    }, [user])

    const handleSubmit = useCallback(async () => {
        if (!/(https:\/\/pbs\.twimg\.com)/.test(pfpImage))
            alert('Sorry, for security and safety reasons, only Twitter images are currently accepted')
        setSaving(true)
        await UpdateCurrentUserAction({ imageUrl: pfpImage, name: username, handle })
        onDone?.()
        ReactQuery.getClient().invalidateQueries(QueryKeys.User.Current._All())
        setSaving(false)
    }, [pfpImage, username, handle, onDone])

    return (
        <>
            <Flex row justify='center' style={{ marginBottom: 40 }}>
                <div style={{ backgroundImage: `url(${pfpImage})`, backgroundSize: 'cover', borderRadius: 100, width: 200, height: 200 }}>
                </div>
            </Flex>
            <Flex row>
                <SkCircleButton><BsCamera color='white' size={UiConstants.iconButtonSize} /></SkCircleButton>
                <Bump w={UiConstants.tightSpacing} />
                <SkTextInput value={pfpImage} onTextChange={setPfpImage} />
            </Flex>
            <Bump h={UiConstants.standardSpacing} />
            <Flex row>
                <SkCircleButton><MdOutlineBadge color='white' size={UiConstants.iconButtonSize} /></SkCircleButton>
                <Bump w={UiConstants.tightSpacing} />
                <SkTextInput value={username} onTextChange={setUsername} />
            </Flex>
            <Bump h={UiConstants.standardSpacing} />
            <Flex row>
                <SkCircleButton><FiAtSign color='white' size={UiConstants.iconButtonSize} /></SkCircleButton>
                <Bump w={UiConstants.tightSpacing} />
                <SkTextInput value={handle} onTextChange={setHandle} />
            </Flex>
            <Bump h={UiConstants.standardSpacing} />
            <SkButton variant='primary' onClick={handleSubmit} title={saving ? 'Saving' : 'Save'} disabled={!pfpImage || user.isLoading || saving} />
        </>
    )

})