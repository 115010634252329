import React from "react";
import { Colors } from "../constants/Colors";
import { UiConstants } from "../constants/UiConstants";
import { useWindowSize } from "../hooks/useWindowSize";
import { Bump } from "./Bump";
import { SkText, SkTextVariant } from "./SkText";

import "./SkButton.scss";

export type SkButtonVariant = 'primary' | 'secondary' | 'secondary-invert' | 'outlined' |  'text'

interface SkButtonProps {
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    htmlId?: string;
    href?: string;
    style?: React.CSSProperties;
    title?: string;
    variant?: SkButtonVariant;
    size?: 'small' | 'normal';
    disabled?: boolean;
    icon?: any;
    children?: any;
    circle?: boolean;
    openInNewTab?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

export const SkButton = React.memo((props: SkButtonProps) => {

    const { className, onClick, style, title, children, variant = 'primary', size = 'normal', type, href, disabled, icon, htmlId, circle, openInNewTab = false } = props
    //const { scale } = useWindowSize()

    // const colors: Record<SkButtonVariant, { bg: string, fg: string }> = {
    //     primary: { bg: Colors.PrimaryBlue, fg: 'white' },
    //     secondary: { bg: '#d9d9d9', fg: Colors.DarkerBackground },
    //     text: { bg: Colors.DarkBackground, fg: 'white' },
    //     'secondary-invert': { bg: Colors.DarkerBackground, fg: 'white' }
    // }

    const handleClick = (e: any) => {
        e.stopPropagation()
        if (!disabled) 
            onClick?.(e)
    }

    const classes = [
        'skButton',
        className,
        variant,
        size === 'small' ? 'small' : '',
        circle ? 'circle' : '',
        disabled ? 'disabled' : ''
    ].join(' ');

    if(href) {
        return (
            <a href={href} className={classes} onClick={handleClick} id={htmlId} style={style} target={openInNewTab ? '_blank' : '_self'} rel="noreferrer">
                {icon}
                {title ? title : children}
            </a>
        );
    }
    else {
        return (
            <button type={type} className={classes} onClick={handleClick} id={htmlId} style={style} disabled={disabled}>
                {icon}
                {title ? title : children}
            </button>
        );
    }

    // return (
    //     <a style={{
    //         //backgroundColor: colors[variant].bg,
    //         // width: '100%',
    //         // display: 'flex',
    //         // justifyContent: 'center',
    //         // alignItems: 'center',
    //         // minHeight: UiConstants.inputHeight * scale,
    //         // boxSizing: 'border-box',
    //         // borderRadius: UiConstants.borderRadius * scale,
    //         // textDecoration: 'none',
    //         // cursor: 'pointer',
    //         ...style
    //     }} className={classes} onClick={ handleClick } id={ htmlId } href={ disabled ? undefined : href } target='_blank'>
    //         { icon ? (
    //             <>
    //                 { icon }
    //                 <Bump w={ 10 } />
    //             </>
    //         ) : null }
    //         { title ? ( title ) : children }
    //     </a>
    // )

});

                {/* // <SkText 
                //     variant='control-label'>
                //     {/* style={{ color: colors[variant].fg, textAlign: 'center', fontWeight: '500' }}> */}
                //     { title }
                // </SkText>
            // ) : children } */}

export const SkCircleButton = React.memo((props: SkButtonProps & { circleSize?: number }) => {

    //const { scale } = useWindowSize();
    //const size = (props.size || (UiConstants.borderRadius * 2)) * scale;

    // return (
    //     <SkButton { ...{ variant: 'secondary-invert', ...props } } style={{ width: size, minWidth: size, height: size, minHeight: size, ...props.style }}>{ props.children }</SkButton>
    // )

    return <SkButton circle {...props}>{props.children}</SkButton>
});