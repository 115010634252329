import React, { useCallback, useEffect, useMemo, useState } from "react"
import { IconBaseProps } from "react-icons"
import { BiVolumeMute } from "react-icons/bi"
import { MdOutlineNotificationsActive, MdOutlineNotificationAdd } from "react-icons/md"
import { RiUserFollowLine, RiUserAddLine } from "react-icons/ri"
import { SkButton, SkButtonVariant, SkCircleButton } from "../../components/SkButton"
import { Colors } from "../../constants/Colors"
import { UiConstants } from "../../constants/UiConstants"
import { ArtistProfile, ArtistSearchResult } from "../../data/models/Artist"
import { ArtistIsType, useArtistIs } from "../../hooks/useArtistIs"

interface ArtistOnListButtonProps {
    //artist: ArtistSearchResult,
    artistHandle: string;
    list: ArtistIsType,
    style?: React.CSSProperties,
    onChange?: (onList: boolean | undefined) => void,
    labels?: { loading: string, on: string, off: string }
    circle?: boolean
    circleSize?: number
    variant?: SkButtonVariant
    children?: any;
    size?: 'small' | 'normal';
}


export const ArtistOnListButton = React.memo((props: ArtistOnListButtonProps) => {

    const { artistHandle, list, style, size, labels = {}, circle, circleSize, children, onChange, variant } = props
    const { onList, add, remove, saving } = useArtistIs(list, artistHandle)

    useEffect(() => onChange?.(onList), [ onList, onChange ])

    const handleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (onList === undefined || saving) { return; }
        if (onList) { remove(); } 
        else { add(); }

        e.preventDefault();
        e.stopPropagation();
    }

    const state = useMemo(() => {
        switch(onList) {
            case undefined: return 'loading'
            case true: return 'on'
            case false: return 'off'
        }
    }, [ onList ])

    // const opacity = useMemo(() => {
    //     if (onList === undefined) return 0
    //     return saving ? 0.4 : 1
    // }, [ saving, onList ])
    const opacity = 1;

    if (circle) return (<SkCircleButton onClick={ handleClick } circleSize={ circleSize } variant={ onList ? 'primary' : 'secondary-invert' } style={{ opacity, ...style }}>{ children }</SkCircleButton>)

    return (<SkButton size={size} title={ labels[state] } variant={ variant } style={{ opacity, ...style }} onClick={ handleClick } />)
})

// "IconProps"
const ip: IconBaseProps = {
    color: 'white',
    size: UiConstants.iconButtonSize * 0.7
}

export const ArtistOnLineCircleButtonManaged = React.memo((props: { list?: ArtistIsType, artistHandle: string }) => {

    const { list, artistHandle } = props
    const [ onList, setOnList ] = useState<boolean | undefined>(false)
    const buttonIcon = useMemo(() => {
        switch (list) { 
            case 'followed': return onList ? <RiUserFollowLine { ...ip } /> : <RiUserAddLine { ...ip } />
            case 'subscribed': return onList ? <MdOutlineNotificationsActive { ...ip } /> : <MdOutlineNotificationAdd { ...ip } />
            case 'muted': return <BiVolumeMute { ...ip } />
            default: return null
        }
    }, [ list, onList ])

    const buttonColor = useMemo(() => {
        if (!onList) return undefined
        switch (list) {
            case 'followed': return Colors.PrimaryBlue;
            case 'subscribed': return Colors.BeautifulMagenta;
            case 'muted': return Colors.FailureRed;
        }
    }, [ list, onList ])

    if (!list || !artistHandle) return null;

    return (
        <ArtistOnListButton list={ list } circle circleSize={ 45 } style={{ backgroundColor: buttonColor, opacity: onList ? 1 : 0.4 }} artistHandle={ artistHandle } onChange={ setOnList }>
            { buttonIcon }
        </ArtistOnListButton>
    )
})

export const ArtistOnLineButtonManaged = React.memo((props: { list?: ArtistIsType, artistHandle: string, onChange?: (onList?: boolean) => void }) => {

    const { list, artistHandle, onChange } = props;
    const [ onList, setOnList ] = useState<boolean | undefined>(false);

    const buttonContent = useMemo(() => {
        switch(list) {
            case 'followed': return onList ? 'Following' : 'Follow';
            case 'subscribed': return onList ? 'Subscribed' : 'Subscribe';
            case 'muted': return 'Muted';
            default: return null;
        }
    }, [ list, onList ]);

    const handleOnChange = useCallback((onList: boolean | undefined) => {
        setOnList(onList);
        onChange?.(onList);
    }, [ onChange ]);

    // const buttonIcon = useMemo(() => {
    //     switch (list) { 
    //         case 'followed': return onList ? <RiUserFollowLine { ...ip } /> : <RiUserAddLine { ...ip } />
    //         case 'subscribed': return onList ? <MdOutlineNotificationsActive { ...ip } /> : <MdOutlineNotificationAdd { ...ip } />
    //         case 'muted': return <BiVolumeMute { ...ip } />
    //         default: return null
    //     }
    // }, [ list, onList ])

    const labels = {
        loading: 'Loading...',
        on: buttonContent || 'on',
        off: buttonContent || 'off'
    }

    const buttonColor = useMemo(() => {
        if (!onList) return undefined
        switch (list) {
            case 'followed': return Colors.PrimaryBlue;
            case 'subscribed': return Colors.BeautifulMagenta;
            case 'muted': return Colors.FailureRed;
        }
    }, [ list, onList ])

    if (!list || !artistHandle) return null;

    return (
        <ArtistOnListButton variant="outlined" size="small" labels={labels} list={ list } style={{ backgroundColor: buttonColor }} artistHandle={ artistHandle } onChange={ handleOnChange }>
            { buttonContent }
        </ArtistOnListButton>
    )
})