import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../constants/Colors";
import { SkRoutes } from "../constants/Routes";
import { ArtistProfile } from "../data/models/Artist";
import { useAuth } from "../hooks/useAuth";
import { Flex } from "./Flex";
import { SkPill } from "./SkPill";
import { SkText } from "./SkText";

export const TagCloud = React.memo((props: { tags: string[], artist?: ArtistProfile, side?: 'left' | 'right', showEditForAdmins?: boolean }) => {

    const { user } = useAuth()
    const { tags = [], artist, side, showEditForAdmins } = props
    const nav = useNavigate();

    return (
        <Flex row style={{ flexWrap: 'wrap', width: '100%' }} justify={ side === 'left' ? 'flex-start' : 'flex-end' }>
            {
                tags.map(tag => (
                    <SkPill key={tag} label={tag} textVariant='descriptor' onClick={ () => nav(SkRoutes.Browse({ tags: [ tag ] })) } unselectedColor={ Colors.MidDarkBackground } />
                ))
            }
            {
                (artist && user?.role === 'admin' && showEditForAdmins)
                    ? <SkPill label='Edit' textVariant='descriptor' unselectedColor={ Colors.DarkerBackground } onClick={ () => nav(SkRoutes.Artist.Edit(artist.handle)) } />
                    : null
            }
        </Flex>
    )

})
