import { useQuery } from "@tanstack/react-query"
import Axios from "axios"
import { SkRoutes } from "../constants/Routes"
import { TokenMemo } from "../data/actions/TokenMemo"
import { MakeUrl } from "../data/actions/Urls"
import { GetCurrentUserAction } from "../data/actions/UserActions"
import { CurrentUser } from "../data/models/CurrentUser"
import { SkResponse } from "../data/models/SkResponse"
import { QueryKeys } from "../data/QueryKeys"
import { useClerk, useAuth as clerkUseAuth } from "@clerk/clerk-react";

export type AuthState = 'not-ready' | 'ok' | 'needs-login'
export type SkAuth = {
    state: AuthState
    userId?: string
    loggedIn?: boolean
    user?: CurrentUser
    tokenPayload?: any
    loading: boolean
    signOut: () => Promise<void>
    redirectToLogin: (redirectUrl?: string) => Promise<void>
    redirectToAnonymousHome: () => void
    openUserProfile: () => void
    getToken: () => Promise<string | null>
}

export const useAuth = (provider?: 'fusionauth' | 'debug' | 'clerk'): SkAuth => {
    switch (provider) {
        case 'debug': return GetDebugAuthProvider();
        //case 'fusionauth': return GetFusionAuthProvier();
        case 'clerk':
        default:
            return GetClerkAuthProvider();
    }
}

const GetDebugAuthProvider = (): SkAuth => {
    const currentUser = useQuery<SkResponse<CurrentUser>>(QueryKeys.User.Current.Profile(), GetCurrentUserAction);

    return {
        state: 'ok',
        tokenPayload: {},
        loggedIn: !!currentUser?.data?.data,
        userId: TokenMemo.Get(),
        redirectToLogin: async (redirectUrl?: string) => {},
        redirectToAnonymousHome: () => {},
        signOut: async () => {},
        loading: currentUser?.isLoading,
        user: currentUser?.data?.data,
        getToken: async () => { return 'debug_12345' },
        openUserProfile: () => { console.warn('openAccountSettings not implemented') }
    };
}

const GetClerkAuthProvider = (): SkAuth => {
    const { user: clerkUser, loaded, redirectToSignIn, signOut, openUserProfile } = useClerk();
    const { getToken } = clerkUseAuth();
    const user = useQuery<SkResponse<CurrentUser>>(QueryKeys.User.Current.Profile(), async () => {
        const token = await getToken();
        return Axios.get(MakeUrl('/users/me'), { headers: { Authorization: `Bearer ${token}` } })
    }, { enabled: loaded });

    let state: AuthState = 'not-ready';
    if(loaded && clerkUser)
        state = 'ok';
    else if(loaded && !clerkUser)
        state = 'needs-login';
    else if(!loaded)
        state = 'not-ready';
        
    return {
        state,
        loading: !loaded,
        userId: clerkUser?.id,
        loggedIn: state === 'ok',
        redirectToLogin: async (redirectUrl?: string) => { redirectToSignIn({ redirectUrl }) },
        redirectToAnonymousHome: () => { window.location.href = SkRoutes.AnonymousHome() },
        signOut: signOut,
        tokenPayload: null,
        getToken: getToken,
        user: user?.data?.data,
        openUserProfile: () => { openUserProfile() }
    };
}
