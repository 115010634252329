import React, { ReducerAction } from "react";
import { UiConstants } from "../constants/UiConstants";
import { Flex } from "./Flex";
import { SkText } from "./SkText";
import { SkButton } from "./SkButton";
import { Bump } from "./Bump";

interface SkTabsProps {
    variant?: 'horizontal' | 'vertical',
    options: { label: string, value: string }[],
    value: string,
    onSelect?: (value: string) => void,
    style?: React.CSSProperties
}

export const SkTabs = React.memo((props: SkTabsProps) => {

    const { variant } = props

    return variant === 'horizontal'
        ? <HorizontalTabs { ...props } />
        : <VerticalTabs { ...props } />

})

const HorizontalTabs = React.memo((props: SkTabsProps) => {

    const { value, options, onSelect, style } = props

    return (
        <Flex className="tabs-horizontal" row style={{ overflow: 'scroll', width: '100%', padding: '10px 0', ...style }}>
            {
                options.map(option => (
                    <SkText
                        key={option.value}
                        variant='section'
                        faded={ option.value !== value }
                        onClick={ () => onSelect?.(option.value) }
                        style={{ marginRight: UiConstants.standardSpacing, whiteSpace: 'nowrap' }}
                    >
                        { option.label }
                    </SkText>
                ))
            }
        </Flex>
    )

})

const VerticalTabs = React.memo((props: SkTabsProps) => {

    const { value, options, onSelect, style } = props

    return (
        <Flex className="tabs-vertical" style={ style } align='stretch'>
            {
                options.map(o => (
                    <SkButton
                        variant={ value === o.value ? 'primary' : 'text' }
                        onClick={ () => onSelect?.(o.value) }
                        style={{ justifyContent: 'flex-start', paddingLeft: UiConstants.borderRadius, marginBottom: 10 }}
                        title={ o.label } />
                ))
            }
        </Flex>
    )

})