import React, { useCallback, useMemo, MouseEvent } from "react";
import { Colors, CommissionColor } from "../constants/Colors";
import { ArtistProfile, ArtistSearchResult } from "../data/models/Artist";
import { Flex } from "./Flex";
import { SkCircleButton } from "./SkButton";
import { BsPlus } from 'react-icons/bs';
import { useWindowSize } from "../hooks/useWindowSize";
import { useSortedArtists } from "../hooks/useSortedArtists";
import { UiConstants } from "../constants/UiConstants";

import "./ArtistPlanet.scss";
import { SkRoutes } from "../constants/Routes";

interface ArtistPlanetProps {
    artist?: ArtistSearchResult; // artist may be undefined if artist account was deleted
    size?: number;
    onClick?: () => void;
    style?: React.CSSProperties;
    noMoon?: boolean;
    selected?: boolean;
    moonHaloColor?: string;
    variant?: 'small' | 'medium' | 'large';
}

export const ArtistPlanet = React.memo((props: ArtistPlanetProps) => {

    const { artist, size = 46, onClick, style, noMoon, moonHaloColor, selected, variant = 'medium' } = props
    const { scale } = useWindowSize()
    const s = useMemo(() => size * scale, [ size, scale ])

    const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
        onClick?.();
        e.preventDefault();
    }, [ onClick ])

    const classes = [
        artist ? artist.commissionStatus : '',
        (selected ? 'selected' : ''),
        (onClick ? 'button' : ''),
        variant
    ];

    return (
        <div className={`artist-planet ${classes.join(' ')}`} onClick={handleClick} style={{
            backgroundImage: `url(${ artist?.imageUrl }), var(--gradient-neutral)`,
            // width: size,
            // height: size,

            // backgroundSize: 'cover',
            // width: s,
            // minWidth: s,
            // height: s,
            // borderRadius: s / 2,
            // cursor: 'pointer',
            ...style
        }}>
            { noMoon ? null : <div className="artist-moon" style={{
                // borderColor: moonHaloColor || Colors.DarkBackground,
                // borderStyle: 'solid',
                // borderWidth: s < 30 ? 3 : 6,
                // backgroundColor: CommissionColor(artist?.commissionStatus || 'unknown'),
                // width: s / 3.5,
                // height: s / 3.5,
                // position: 'relative',
                // borderRadius: s / 2,
                // left: s * 0.65,
                // top: s * 0.65
            }}></div> }
        </div>
    )

},)

interface ArtistPlanetListProps {
    row?: boolean;
    size?: number;
    artists: ArtistProfile[];
    selectedHandle?: string;
    onAdd?: () => void;
    onSelect: (artist: ArtistProfile) => void;
}

export const ArtistPlanetList = React.memo((props: ArtistPlanetListProps) => {

    const { artists, selectedHandle, row, onSelect, onAdd } = props;
    const sorted = useSortedArtists(artists);

    return (
        <Flex className="artist-planet-list" row={ row }>
            {
                sorted.map(a => (
                    <ArtistPlanet key={a.handle} variant="large" artist={ a } onClick={ () => onSelect(a) } selected={a.handle === selectedHandle} />
                ))
            }
            {
                onAdd ? (
                    <SkCircleButton onClick={ onAdd }>
                        {/* <BsPlus size={ size * scale } color='white' /> */}
                        <BsPlus color='white' />
                    </SkCircleButton>
                ) : null
            }
        </Flex>
    );
});
