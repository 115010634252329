import { useEffect } from "react";
import { ScreenWrapper } from "../components/ScreenWrapper";
import { SkButton } from "../components/SkButton";
import { SkRoutes } from "../constants/Routes";
import { useAuth } from "../hooks/useAuth";

import "./WelcomeScreen.scss";
import { useNavigate } from "react-router-dom";

import { MdNotificationsActive } from "react-icons/md";

export const WelcomeScreen = () => {

    const { redirectToLogin, state, loggedIn } = useAuth();
    const nav = useNavigate();

    useEffect(() => {
        if(state === 'ok' && loggedIn) {
            nav(SkRoutes.Home());
        }
    }, [state, loggedIn, redirectToLogin]);

    return (
        <ScreenWrapper className="welcome-screen" skipAuth>
            <div className="welcome-header">
                <div className="header-1">Hi internet animal person</div>
                <div className="header-2">Does this sound like you?</div>

                <div className="welcome-posts">
                    <img className="welcome-post post1" src="/images/post1.png" alt="I really want a custom 3D model but don't know any artists who make them" />
                    <img className="welcome-post post2" src="/images/post2.png" alt="How the hecc do you commission art??" />
                    <img className="welcome-post post3" src="/images/post3.png" alt="Is anyone open for ref sheets? I really need a new one" />
                </div>

                <div className="header-3">We got you.</div>
            </div>
            <div className="welcome-content">
                <h1>Find Artists Fast!</h1>
                <hr />

                <div className="auth-buttons">
                    <SkButton variant='primary' title='Sign Up' onClick={() => redirectToLogin(SkRoutes.Home())} />
                    <SkButton variant='outlined' title='Sign In' onClick={() => redirectToLogin(SkRoutes.Home())} />
                </div>

                <h3><Emoji code={0x1F4EC} />Get Notified of Artist Openings</h3>
                <p>Are you tired of missing commission openings from your favourite artists? Skrunkle allows you to get mobile notifications within minutes of your favourite artist opening.</p>
                
                <h3><Emoji code={0x1F4A1} />Discover New Artists</h3>
                <p>Having trouble finding artists open right now? Skrunkle lets you explore artists open for commissions right now so you can get that piece you've been dreaming of <i>today</i>.</p>

                <h3><Emoji code={0x1F60E} />No Extra Work</h3>
                <p>Skrunkle doesn't make artists do any more work, they have enough on their plate! We simply watch for artists making posts indicating they're open for commissions or opening soon.</p>

                <hr />
                <p>Need info or have a question? Check us out on Bluesky at <a href="https://bsky.app/profile/skrunkle.com">@skrunkle.com</a></p>
            </div>
        </ScreenWrapper>
    );
};

function Emoji(props: { code: number }){
    const { code } = props;

    return (
        <span className="welcome-emoji">{String.fromCodePoint(code)}</span>
    )
}